/**
 * 前端项目配置文件
 */
const projectName = '鲨鱼' // 项目名称
//const ip = '127.0.0.1'
const ip = 'sypw.nuolafacai.top'
const port= '9090'
const serverHost = 'http://'+ip+':'+port // 接口服务器地址 举例:http://127.0.0.1:9090

export { projectName, serverHost }
