import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { projectName } from '../../config/config.default'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginUser.vue'),
    meta: {
      title: '用户登录'
    }
  },
  {
    path: '/loginForAdmin',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '管理员登录'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      title: '页面失踪啦'
    }
  },
    //隔离tab栏的路由
  {
    path: '/front/home',
    name: 'FrontHome',
    component: () => import('../views/front/Home.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/front',
    name: 'Front',
    component: () => import('../views/front/Front'),
    children: [
      //前台路由
      // {
      //   path: 'home',
      //   name: 'FrontHome',
      //   component: () => import('../views/front/Home.vue'),
      //   meta: {
      //     title: '首页'
      //   }
      // },
      {
        path: 'password',
        name: 'Password',
        component: () => import('../views/front/Password.vue'),
        meta: {
          title: '修改密码'
        }
      },
      {
        path: 'person',
        name: 'Person',
        component: () => import('../views/front/Person.vue'),
        meta: {
          title: '个人信息'
        }
      },
      //前台路由
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// 重置路由
export const resetRouter = () => {
  router.matcher = new VueRouter({
    mode: 'history',
    routes
  })
}

// 注意：刷新页面会导致页面路由重置
export const setRoutes = () => {
  const storeMenus = localStorage.getItem('menus')
  if (storeMenus) {
    // 拼装动态路由
    const manageRoute = {
      path: '/',
      name: 'Manage',
      component: () => import('../views/Manage.vue'),
      redirect: '/home',
      children: [
        {
          path: 'person',
          name: '个人信息',
          component: () => import('../views/Person.vue'),
          meta: {
            title: '个人信息'
          }
        },
        {
          path: 'password',
          name: '修改密码',
          component: () => import('../views/Password.vue'),
          meta: {
            title: '修改密码'
          }
        },
        //代码生成器相关代码
        {
          path: 'table',
          name: '代码生成',
          component: () => import('../views/Table.vue'),
          meta: {
            title: '代码生成'
          }
        },
        {
          path: 'module',
          name: '模块生成',
          component: () => import('../views/Module'),
          meta: {
            title: '模块生成'
          }
        },
        //代码生成器相关代码
      ]
    }
    const menus = JSON.parse(storeMenus)
    menus.forEach((item) => {
      if (item.path) {
        // 当且仅当path不为空的时候才去设置路由
        let itemMenu = { path: item.path.replace('/', ''), name: item.name, component: () => import('../views/' + item.pagePath + '.vue'), meta: {title: item.name} }
        manageRoute.children.push(itemMenu)
      } else if (item.children.length) {
        item.children.forEach((item) => {
          if (item.path) {
            let itemMenu = { path: item.path.replace('/', ''), name: item.name, component: () => import('../views/' + item.pagePath + '.vue'), meta: {title: item.name} }
            manageRoute.children.push(itemMenu)
          }
        })
      }
    })

    // 获取当前的路由对象名称数组
    const currentRouteNames = router.getRoutes().map((v) => v.name)
    if (!currentRouteNames.includes('Manage')) {
      // 动态添加到现在的路由对象中去
      router.addRoute(manageRoute)
    }
  }
}

// 每次刷新页面都要重新设置路由，否则路由就会被重置
setRoutes()

router.beforeEach((to, from, next) => {
  NProgress.start() // 开启加载进度条
  localStorage.setItem('currentPathName', to.name) // 设置当前的路由名称
  store.commit('setPath')
  if (!to.matched.length) {
    const menus = JSON.parse(localStorage.getItem('menus'))
    const user = JSON.parse(localStorage.getItem('user'))
    //第一次打开系统或者登出以后menus和user都为空，则需要跳到登录
    if(menus==null && user==null){
      next("/login")
      return
    }
    //当登录过以后，有后台路由的角色menus不为空
    if (menus.length==0) {
      next('/front/home')
    } else {
      next('/404')
    }
  } else {
    next()
  }
})

router.afterEach((to) => {
  NProgress.done() // 关闭加载进度条
  document.title = to.meta.title ? `${to.meta.title} - ${projectName}` : projectName // 设置页面标题
})

export default router
